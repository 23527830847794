import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image'
import styled from 'styled-components'
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  LinkedinShareButton,
} from 'react-share'
import { Facebook, Twitter, Mail, Linkedin } from 'react-feather'

import ContactForm from '../components/estimate-form'
import Breadcrumbs from '../components/breadcrumbs'

const ContactCard = styled.div`
  top: 30px !important;
  background: rgb(249 249 249);
`

const PressReleaseLayout = styled.div`
  background: #fff;
  .articleBody {
    max-width: 900px;
    margin: 0 auto;
    padding: 1em;
  }
`

const Header = styled.div`
  text-align: left;
  margin: 0 auto;
  padding-bottom: 0em;
  margin-bottom: 1em;
  border-bottom: 1px solid #eaeaea;
  
  h1 {
    font-size: calc(1.15vw + 1em);
    margin-bottom: 0.25em;
    line-height: 1.3;
    font-weight: 600;
  }
  
  p {
    color: #666;
  }
  
  background: #f7f7f7;
`

const ShareThisPost = styled.div`
  padding-bottom: 1em;
  p {
    font-weight: bold;
    margin: 0;
  }
  display: flex;
  align-items: center;
  .SocialMediaShareButton {
    display: flex;
    padding: 0 9px;
  }
  svg {
    stroke: none;
    fill: #888;
    cursor: pointer;
  }
`

class PressReleaseTemplate extends Component {
  render() {
    const post = this.props.data.contentfulPressRelease
    const siteurl = this.props.data.site.siteMetadata.siteUrl
    const relatedPosts = this.props.data.related

    return (
      <Layout pageProps={this.props}>
        <div className="bg-white border-b border-gray-100">
          <nav
            className="flex container mx-auto px-4 py-3"
            aria-label="Breadcrumb"
          >
            <Breadcrumbs
              links={[
                { to: '/', label: 'Home' },
                { to: '/press-releases/', label: 'Press Releases' },
                {
                  to: this.props.location.pathname,
                  label: post.title,
                },
              ]}
            />
          </nav>
        </div>

        <SEO
          title={post.metaTitle ? post.metaTitle : post.title}
          description={post.metaDescription ? post.metaDescription : post.title}
          img={getSrc(post.heroImage.metaTagImage)}
        />
        <PressReleaseLayout className="relative">
          <article itemScope itemType="https://schema.org/Article">
            <Header>
              <div className="lg:flex lg:container lg:row mx-auto">
                <div
                  className="container py-12 text-left blogimgmw w-full lg:w-2/3 relative z-20 pt-8"
                  style={{ maxWidth: '900px' }}
                >
                  <h1 itemProp="name headline">{post.title}</h1>
                  <p>
                    <time itemProp="datePublished">{post.publishDate}</time> — by <span itemProp="author">{post.author.name}</span>
                  </p>

                  <br />

                  <div className="mx-auto overflow-hidden rounded shadow-lg relative z-20">
                    <GatsbyImage
                      image={getImage(post.heroImage)}
                      alt={post.title}
                      style={{
                        height: '400px',
                        objectFit: 'cover',
                        boxShadow: '1px 2px 8px 1px hsla(0,0%,0%,0)',
                      }}
                    />
                    <ShareThisPost className="bg-white px-6 justify-content-end p-2">
                      <p className="text-base tracking-widest uppercase">
                        Share —
                      </p>
                      <FacebookShareButton
                        url={siteurl + '/press-releases/' + post.slug}
                        quote={post.title}
                        hashtag={'#usqualityconstruction'}
                        className="bg-white hover:bg-gray-200 cursor-pointer flex h-8 items-center p-1 rounded-full w-8 mr-1"
                      >
                        <Facebook size={20} />
                      </FacebookShareButton>

                      <TwitterShareButton
                        url={siteurl + '/press-releases/' + post.slug}
                        title={post.title}
                        className="bg-white hover:bg-gray-200 cursor-pointer flex h-8 items-center p-1 rounded-full w-8 mr-1"
                      >
                        <Twitter size={20} />
                      </TwitterShareButton>
                      <LinkedinShareButton
                        url={siteurl + '/press-releases/' + post.slug}
                        title={post.title}
                        className="bg-white hover:bg-gray-200 cursor-pointer flex h-8 items-center p-1 rounded-full w-8 mr-1"
                      >
                        <Linkedin size={20} />
                      </LinkedinShareButton>
                      <EmailShareButton
                        subject={post.title}
                        url={siteurl + '/press-releases/' + post.slug}
                        openWindow={true}
                        body={'Check out this press release: '}
                        className="bg-white hover:bg-gray-200 cursor-pointer flex h-8 items-center p-1 rounded-full w-8 mr-1"
                      >
                        <Mail size={20} />
                      </EmailShareButton>
                    </ShareThisPost>
                  </div>
                </div>
                <div className="w-full lg:w-1/3"></div>
              </div>
            </Header>
            <div className="lg:flex lg:container lg:row mx-auto">
              <div className="relative articleBody w-full lg:w-2/3 z-10">
                <div
                  itemProp="articleBody"
                  dangerouslySetInnerHTML={{ __html: post.body.body }}
                />
              </div>
              <div className="w-full lg:w-1/3"></div>
              <div className="lg:container block lg:flex lg:row w-full h-full top-0 justify-end relative lg:absolute">
                <div className="w-full lg:w-2/3 invisible" />
                <div className="w-full lg:w-1/3 py-12 relative justify-self-end p-6">
                  <ContactCard className="border-radius p-6 cardborder shadow-lg sticky top-0">
                    <h3 className="text-center mb-1">Get Started Today!</h3>
                    <p className="block text-center mb-6 text-base font-semibold">
                      Request a FREE In-Home Estimate
                    </p>

                    <div className="mb-6 sidebarform">
                      <ContactForm
                        showLabels
                        showPlaceHolders
                        pageLocation={
                          this.props.location.href
                            ? this.props.location.href
                            : 'Not provided'
                        }
                      />
                    </div>
                  </ContactCard>
                </div>
              </div>
            </div>
          </article>
        </PressReleaseLayout>

        {relatedPosts.edges && relatedPosts.edges.length > 0 && (
          <div className="container mx-auto px-3 py-8">
            <h2>Other Press Releases</h2>
            <div className="grid grid-cols-2 gap-4 lg:grid-cols-3">
              {relatedPosts.edges.map((item, i) => (
                <Link
                  key={i}
                  to={`/press-releases/${item.node.slug}`}
                  className="bg-white rounded-lg shadow hover:shadow-lg"
                >
                  <GatsbyImage
                    image={getImage(item.node.heroImage)}
                    style={{ height: '150px' }}
                    alt={item.node.title}
                  />
                  <div className="p-3 py-4">
                    <span className="text-gray-700 text-base">
                      {item.node.publishDate}
                    </span>
                    <span className="font-semibold leading-tight text-gray-900 block">
                      {item.node.title}
                    </span>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        )}


      </Layout>
    )
  }
}

export default PressReleaseTemplate

export const pageQuery = graphql`
  query PressReleaseBySlug($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }

    contentfulPressRelease(slug: { eq: $slug }) {
      title
      slug
      metaTitle
      metaDescription
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        gatsbyImageData(width: 1500, placeholder: BLURRED)
        metaTagImage: gatsbyImageData(
          width: 1200
          height: 630
          placeholder: BLURRED
        )
      }
      author {
        name
      }
      body {
        body
      }
    }

    related: allContentfulPressRelease(
      filter: { slug: { ne: $slug } }
      sort: { fields: publishDate, order: DESC }
      limit: 6
    ) {
      edges {
        node {
          id
          title
          publishDate(formatString: "MMMM Do, YYYY")
          slug
          heroImage {
            gatsbyImageData(width: 400, quality: 70, placeholder: NONE)
          }
          metaDescription
          author {
            name
          }
        }
      }
    }

    citiesList: allContentfulServiceArea(sort: { fields: location }) {
      edges {
        node {
          location
          slug
          zipCodes
          stateAbbreviation
        }
      }
    }
  }
`
